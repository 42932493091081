var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"7be89531732e2cb9adfeb38c54fccef10a057828"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { BrowserTracing } from '@sentry/tracing'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENVIRONMENT =
  process.env.SENTRY_ENVIRONMENT ||
  process.env.VERCEL_GIT_COMMIT_REF?.split('/').join('-') ||
  'development'

Sentry.init({
  environment: SENTRY_ENVIRONMENT,
  release: process.env.VERCEL_GIT_COMMIT_SHA,
  dsn:
    SENTRY_DSN ||
    'https://ea3d7996ec7a4dd482c9718e8abf8225@o1173866.ingest.sentry.io/6269433',
  tracesSampleRate: 0.25,
  integrations: [new BrowserTracing()],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  beforeSend(event, hint) {
    // console.log(event, hint)

    if (process.env.NEXT_PUBLIC_SENTRY_DISABLE) {
      return null
    }

    return event
  },
})
